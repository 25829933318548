<template>
  <div v-if="checkPrivilege('tax:read')">
    <app-section-loader :status="loading_page" />
    <progress-dialog ref="progressDialog" />

    <dialog-update-sort ref="dialogUpdateSort" @onClick="updateSort('bulk')">
    </dialog-update-sort>

    <dialog-confirm-add ref="dialogConfirmAdd" @onClick="addInstance()">
    </dialog-confirm-add>
    <dialog-confirm-import
      ref="dialogConfirmImport"
      :has-get-next-code="hasGetNextCode"
      :csv-duplicate="csvDuplicate"
      :db-duplicate="dbDuplicate"
      :csv-blank="csvBlank"
      :delete-names="deleteNames"
      @onClick="importCsv()"
    >
    </dialog-confirm-import>

    <dialog-add
      ref="dialogAdd"
      :inputs="inputs"
      :label="definition.journal.tax_class_name"
      @onClick="checkAdd()"
    >
      <template v-slot:additionalForm>
        <v-row>
          <v-col cols="8">
            <v-text-field
              type="number"
              label="税率(%)"
              min="0"
              max="100"
              v-model="inputs.taxRate"
              :rules="inputRulesNumber"
              dense
              filled
              outlined
              required
              hint="10%の場合は「10」と入力"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              label="軽減税率"
              v-model="inputs.reducedTax"
              color="primary"
              class="mt-1"
              hide-details
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="inputs.exportCode"
              label="仕訳エクスポート用コード"
              dense
              filled
              outlined
            />
          </v-col>
        </v-row>
      </template>
    </dialog-add>

    <dialog-edit
      ref="dialogEdit"
      :inputs="inputs"
      :label="definition.journal.tax_class_name"
      @onClick="submitEditTax()"
    >
      <template v-slot:additionalForm>
        <v-row>
          <v-col cols="8">
            <v-text-field
              type="number"
              label="税率(%)"
              min="0"
              max="100"
              v-model="inputs.taxRate"
              :rules="inputRulesNumber"
              dense
              filled
              outlined
              required
              hint="10%の場合は「10」と入力"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              label="軽減税率"
              v-model="inputs.reducedTax"
              color="primary"
              class="mt-1"
              hide-details
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="inputs.exportCode"
              label="仕訳エクスポート用コード"
              dense
              filled
              outlined
            />
          </v-col>
        </v-row>
      </template>
    </dialog-edit>

    <dialog-bulk-edit
      ref="dialogBulkEdit"
      :value="bulkEditInputs"
      @onClick="submitBulkEdit()"
    />

    <dialog-bulk-delete ref="dialogBulkDelete" @onClick="submitBulkDelete()" />

    <input-delete-dialog
      ref="dialogDelete"
      max-width="400"
      :title="$t('message.delete')"
      :message="$t('message.tax_class_delete_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.table_delete_btn')"
      @submit="
        deleteInstance()
        $refs.dialogDelete.close()
      "
    />

    <master-table
      ref="masterTable"
      name="tax"
      :headers="computedHeaders"
      :items="data"
      :search="search"
      :loading="loading_page"
      @click:create="
        $refs.dialogAdd.open()
        resetForm()
      "
      @click:bulk_edit="
        setSelectedIds($event)
        $refs.dialogBulkEdit.open()
      "
      @click:bulk_delete="
        setSelectedIds($event)
        $refs.dialogBulkDelete.open()
      "
      @click:read_csv="readCsv($event)"
      @click:get_sample="exportMaster('tax_class', 'sample')"
      @click:export="exportMaster('tax_class', 'all')"
      @click:update_sort="$refs.dialogUpdateSort.open()"
      @click:updateSort="updateSort('one')"
      @click:edit="
        selectTax($event)
        $refs.dialogEdit.open()
      "
      @click:delete="
        selectTax($event)
        $refs.dialogDelete.open()
      "
    >
      <template #item.rate="{ item }">
        {{ item.rate }} %
        <span v-if="item.reduced_tax" class="sw-badge-primary ml-2">
          軽減税率</span
        >
      </template>
    </master-table>
  </div>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import AppConfig from 'Constants/AppConfig'
import Axios from 'axios'
import DialogUpdateSort from '@/pages/master/@components/@shared/DialogUpdateSort/DialogUpdateSort'
import DialogConfirmAdd from '@/pages/master/@components/@shared/DialogConfirmAdd/DialogConfirmAdd'
import DialogConfirmImport from '@/pages/master/@components/@shared/DialogConfirmImport/DialogConfirmImport'
import DialogAdd from '@/pages/master/@components/@shared/DialogAdd/DialogAdd'
import DialogEdit from '@/pages/master/@components/@shared/DialogEdit/DialogEdit'
import DialogBulkEdit from '@/pages/master/@components/@shared/DialogBulkEdit/DialogBulkEdit'
import DialogBulkDelete from '@/pages/master/@components/@shared/DialogBulkDelete/DialogBulkDelete'
import MasterTable from '@/pages/master/@components/@shared/MasterTable/MasterTable'
import { toastErrorOnCheckImport } from '@/pages/master/@modules/mutators/toast'
import {
  initInputsObj,
  updateTableDataStatus,
  deleteTableData,
} from 'Helpers/master/index'

export default {
  components: {
    DialogUpdateSort,
    DialogConfirmAdd,
    DialogConfirmImport,
    DialogAdd,
    DialogEdit,
    DialogBulkEdit,
    DialogBulkDelete,
    MasterTable,
  },
  props: {
    definition: Object,
    getJournalItems: Function,
    getMaster: Function,
    exportMaster: Function,
    sortMaster: Function,
  },
  data() {
    var t = this
    return {
      name: '税区分',
      import_data: {},

      hasGetNextCode: false,
      csvDuplicate: {
        name_code: [],
        name: [],
        code: [],
      },
      dbDuplicate: {
        name_code: [],
        name: [],
        code: [],
      },
      csvBlank: {
        name_code: [],
        name: [],
        code: [],
      },
      deleteNames: [],
      fileReaded: 0,
      loading_page: false,
      valid: false,
      valid_edit: false,
      data: [],
      search: '',
      selectedIds: [],
      items: [],
      headers: [
        { width: 90, text: t.$t('message.sort_num'), value: 'sort_num' },
        { width: 140, text: t.$t('message.code'), value: 'code' },
        { text: t.definition.journal.tax_class_name, value: 'name' },
        {
          width: 150,
          text: t.$t('message.accounting_tax_rate'),
          value: 'rate',
        },
        { width: 180, text: '仕訳エクスポート用コード', value: 'export_code' },
        { width: 80, text: '表示', value: 'status', align: 'center' },
        { width: 160, text: '', value: 'action', sortable: false },
      ],

      inputRulesNumber: [
        (v) => !!v || t.$t('message.form_input_required_number'),
      ],
      selectedInstance: {},
      inputs: {
        code: '',
        name: '',
        taxRate: '',
        reducedTax: false,
        status: 1,
        exportCode: '',
      },
      bulkEditInputs: { status: 1 },
      pagination: AppConfig.pagination,
    }
  },
  computed: {
    computedHeaders: function () {
      const resource = 'tax'
      const hasEditCol =
        this.checkPrivilege(`${resource}:update`) ||
        this.checkPrivilege(`${resource}:delete`)
      if (hasEditCol) {
        return this.headers
      } else {
        return this.headers.slice(0, this.headers.length - 1)
      }
    },
  },
  mounted() {
    try {
      this.getData()
    } catch (e) {
      console.log(e)
    }
  },
  watch: {
    fileReaded: function (val) {
      if (val > 0) {
        this.checkCsv()
      }
    },
  },
  methods: {
    getData() {
      this.loading_page = true
      this.getMaster('tax_class').then((data) => {
        this.data = data
        this.loading_page = false
      })
    },
    updateSort(mode) {
      try {
        let data = {
          name: 'tax_class',
          mode: mode,
          items: this.data,
          order_value: this.$refs.dialogUpdateSort.form.order_value,
          order_type: this.$refs.dialogUpdateSort.form.order_type,
        }
        this.sortMaster(data).then((result) => {
          if (result.status === 'success') {
            this.data = result.items
            this.$refs.dialogUpdateSort.close()
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    setBlanktoFileInput() {
      document.querySelector('#tax_import_csv').value = ''
    },
    readCsv(event) {
      try {
        var t = this
        var f = event.target.files[0]
        var reader = new FileReader()
        reader.onload = (function (f) {
          return function (e) {
            var data = {
              base64: e.target.result,
            }
            t.$store
              .dispatch('importCSV_TaxClass', { data })
              .then((response) => {
                let result_array = response
                t.import_data = {
                  name: 'tax_class',
                  items: result_array,
                }
                t.fileReaded += 1
              })
          }
        })(f)
        t.setBlanktoFileInput()
        reader.readAsDataURL(f)
      } catch (e) {
        console.log(e)
      }
    },
    checkCsv() {
      var data = this.import_data

      this.$refs.progressDialog.open(
        this.$t('message.progress_check_import_naster')
      )

      this.$store
        .dispatch('checkImportMasterData', data)
        .then((response) => {
          this.$refs.progressDialog.close()

          this.csvDuplicate = response.data.csv_duplicate
          this.dbDuplicate = response.data.db_duplicate
          this.csvBlank = response.data.csv_blank

          this.hasGetNextCode = false
          if (this.csvBlank.code[0]) {
            this.hasGetNextCode =
              !response.data.db_exist &&
              this.csvBlank.code[0].count === data.items.length
          }

          if (
            this.csvDuplicate.count > 0 ||
            this.dbDuplicate.count > 0 ||
            this.csvBlank.count > 0
          ) {
            this.$refs.dialogConfirmImport.open()
          } else {
            this.importCsv()
          }
        })
        .catch((err) => {
          this.$refs.progressDialog.close()
          toastErrorOnCheckImport(err)
        })
    },
    async importCsv() {
      if (this.hasGetNextCode) {
        const props = {
          message: 'コードを自動で付与します。よろしいですか？',
          confirmShowDialog: true,
          localStorageSetName: 'confirmMasterCodeAutomatically',
        }
        const yes = await this.$confirm(props)
        if (!yes) {
          return
        }

        let nextCode = 1001
        for (const data of this.import_data.items) {
          data.code = String(nextCode)
          nextCode += 1
        }
      }
      if (this.dbDuplicate.count > 0) {
        const props = {
          message: 'コードが重複するデータは上書きされます。よろしいですか？',
          confirmShowDialog: true,
          localStorageSetName: 'confirmMasterOverwrite',
        }
        const yes = await this.$confirm(props)
        if (!yes) {
          return
        }
      }
      try {
        var data = this.import_data

        this.$refs.progressDialog.open(
          this.$t('message.progress_import_naster')
        )

        this.$store.dispatch('importMasterData', { data }).then((response) => {
          this.$refs.progressDialog.close()

          if (response.data.status == 'success') {
            Snackbar.success(this.$t('message.success_import_csv'))

            this.data = response.data.list
            this.getJournalItems()
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
        Snackbar.error(e)
      }
    },
    resetForm() {
      try {
        this.$refs.dialogAdd.resetValidation()
        this.$refs.dialogEdit.resetValidation()
        initInputsObj(this.inputs)
        this.inputs.taxRate = ''
        this.inputs.reducedTax = false
        this.inputs.exportCode = ''
      } catch {}
    },
    submitEditTax() {
      try {
        if (this.$refs.dialogEdit.validate()) {
          Axios.put(
            this.$store.getters.apiTaxclassUrl,
            {
              id: this.selectedInstance.id,
              code: this.inputs.code,
              name: this.inputs.name,
              status: this.inputs.status,
              rate: this.inputs.taxRate,
              is_reduced_tax: this.inputs.reducedTax,
              export_code: this.inputs.exportCode,
              organization_id: this.$store.getters.getOrganizationID,
              user_organization_id: this.$store.getters.getUserOrganizationID,
            },
            { headers: { Authorization: this.$store.getters.getAuthToken } }
          ).then((response) => {
            if ('error' in response.data) {
              Snackbar.error(response.data.error)
            } else {
              this.selectedInstance.name = this.inputs.name
              this.selectedInstance.code = this.inputs.code
              this.selectedInstance.status = this.inputs.status
              this.selectedInstance.rate = parseFloat(
                Math.round(this.inputs.taxRate * 100) / 100
              ).toFixed(2)
              this.selectedInstance.reduced_tax = this.inputs.reducedTax
              this.selectedInstance.export_code = this.inputs.exportCode
              this.$refs.dialogEdit.close()
              this.resetForm()
              Snackbar.success(this.$t('message.success'))
              this.getJournalItems()
            }
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    submitBulkEdit() {
      try {
        const ids = this.selectedIds
        const status = this.bulkEditInputs.status
        if (ids.length > 0) {
          Axios.put(
            this.$store.getters.apiTaxclassesUrl,
            {
              ids: ids,
              status: status,
              organization_id: this.$store.getters.getOrganizationID,
              user_organization_id: this.$store.getters.getUserOrganizationID,
            },
            { headers: { Authorization: this.$store.getters.getAuthToken } }
          ).then((response) => {
            if ('error' in response.data) {
              Snackbar.error(response.data.error)
            } else {
              updateTableDataStatus(this.data, ids, status)
              this.$refs.dialogBulkEdit.close()
              Snackbar.success(this.$t('message.success'))
              this.getJournalItems()
            }
          })
        } else {
          Snackbar.error(this.$t('message.no_selected_data'))
        }
      } catch (err) {
        console.log(err)
      }
    },
    submitBulkDelete() {
      try {
        const ids = this.selectedIds
        if (ids.length > 0) {
          Axios.delete(this.$store.getters.apiTaxclassesUrl, {
            data: { ids: ids },
            headers: { Authorization: this.$store.getters.getAuthToken },
          }).then((response) => {
            if ('error' in response.data) {
              Snackbar.error(response.data.error)
            } else {
              deleteTableData(this.data, ids, status)
              this.$refs.dialogBulkDelete.close()
              this.$refs.masterTable.resetSelected()
              Snackbar.success(this.$t('message.success'))
              this.getJournalItems()
            }
          })
        } else {
          Snackbar.error(this.$t('message.no_selected_data'))
        }
      } catch (err) {
        console.log(err)
      }
    },
    deleteInstance() {
      try {
        Axios.delete(this.$store.getters.apiTaxclassUrl, {
          data: { id: this.selectedInstance.id },
          headers: { Authorization: this.$store.getters.getAuthToken },
        }).then((response) => {
          if ('error' in response.data) {
            Snackbar.error(response.data.error)
          } else {
            let index = this.data.indexOf(this.selectedInstance)
            this.data.splice(index, 1)
            this.$refs.masterTable.resetSelected()
            Snackbar.success(this.$t('message.success'))
            this.getJournalItems()
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getCurrentAppLayoutHandler() {
      return getCurrentAppLayout(this.$router)
    },
    selectTax(data) {
      try {
        this.selectedInstance = data
        this.inputs.name = data.name
        this.inputs.code = data.code
        this.inputs.status = data.status
        this.inputs.taxRate = data.rate
        this.inputs.reducedTax = data.reduced_tax
        this.inputs.exportCode = data.export_code
      } catch {}
    },
    setSelectedIds(ids) {
      try {
        this.selectedIds = ids
      } catch (e) {
        console.log(e)
      }
    },
    async addInstance() {
      try {
        const data = {
          name: this.inputs.name,
          rate: this.inputs.taxRate,
          code: this.inputs.code,
          status: this.inputs.status,
          reducedTax: this.inputs.reducedTax,
          exportCode: this.inputs.exportCode,
        }
        this.$store.dispatch('addTax', { data }).then((response) => {
          if (response.data.status == 'success') {
            const toAdd = {
              id: response.data.data.id,
              name: response.data.data.name,
              rate: response.data.data.rate,
              code: response.data.data.code,
              status: response.data.data.status,
              reduced_tax: response.data.data.reduced_tax,
              export_code: response.data.data.export_code,
              sort_num: response.data.data.sort_num,
            }
            this.$refs.dialogAdd.close()
            this.data.push(toAdd)

            Snackbar.success(this.$t('message.success'))
            this.resetForm()
            this.getJournalItems()
          }
        })
      } catch {}
    },
    checkAdd() {
      try {
        if (this.$refs.dialogAdd.validate()) {
          const data = {
            data: this.data,
            item: {
              code: this.inputs.code,
              name: this.inputs.name,
              status: this.inputs.status,
            },
            keys: ['name', 'code'],
          }
          this.$store.dispatch('checkDuplicates', data).then((result) => {
            if (result.isDuplicate === true) {
              this.$refs.dialogConfirmAdd.set(result.duplicateItems)
              this.$refs.dialogConfirmAdd.open()
            } else {
              this.addInstance()
            }
          })
        }
      } catch {}
    },
  },
}
</script>
